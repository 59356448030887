import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import MainTitle from '../components/MainTitle'
import ContactForm from '../components/ContactForm'

const FaleConosco = ({ data }) => (
  <Layout>
    <SEO title="Fale Conosco" />

    <MainTitle
        content={ `<h1>Fale <em>Conosco</em></h1><p>Todas as formas de comunicação 
        com a gente.</p>`} />

    <ContactForm />

  </Layout>
)

export default FaleConosco
